<template>
  <NavBar/>
  <div class="main-bg">
    <h2 class="title">
      {{$t('Требования')}}:
    </h2>
    <div class="needs-items">
      <ul class="rent-needs">
        <li class="rent-needs-item"  v-for="item in items" :key="item.item">
          <img :src="require(`../assets/imgs/${ item.item.image }.png`)" alt="">
          {{$t(item.item.text)}}
        </li>
      </ul>
      <ul class="rent-needs" >
        <li class="rent-needs-item"  v-for="item in i" :key="item.item">
          <img :src="require(`../assets/imgs/${ item.item.image }.png`)" alt="">
          {{$t(item.item.text)}}
        </li>
      </ul>
    </div>
  </div>
  <ContactForm/>
</template>

<script>
import NavBar from '../components/NavBar'
import ContactForm from '../components/ContactForm'
export default {
  name: 'Needs',
  components: {
    NavBar,
    ContactForm
  },
  data () {
    return {
      items: [
        {
          item: {
            text: 'фото',
            image: 'account'
          }
        },
        {
          item: {
            text: 'друзі',
            image: 'exam1'
          }
        },
        {
          item: {
            text: 'аренда',
            image: 'credit'
          }
        }
      ],
      i: [
        {
          item: {
            text: 'профиль',
            image: 'test'
          }
        },
        {
          item: {
            text: 'Возраст',
            image: 'examination'
          }
        },
        {
          item: {
            text: 'Аккаунт',
            image: 'exam'
          }
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

.needs-items{
  display: flex;
  width: 70%;
  margin: auto;
  justify-content: space-around;
}

.rent-needs {

  &-item {
    display: flex;
    align-items: center;
    & img{
      width: 50px;
      margin-right: 10px;
    }
    &::before{
      content: none;
    }
  }
}

</style>
